const determineAppServerKey = () => {
  const vapidPublicKey ="BGxNv1YMcohiiE8ALpbnxutkWrB8sevf5HPGLOxBp3j7x59gdgxAjKXi35-dh8UKueqTONMgV_x_j1GDgcd04Nw";
  return urlBase64ToUint8Array(vapidPublicKey);
};
  
const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};


const serviceWorker = () => {
  navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/worker.js`).then((response) => {
    return response.pushManager.getSubscription().then((subscription) => {
    response.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: determineAppServerKey(),
      });
    });
  });
};
  
export default serviceWorker;
  
import React, { useState, useEffect, useRef } from "react";
import { Card, Row, Col, Form, Input, Button, Spin, Modal } from "antd";
import { serverTokenUrl, serverUrl } from "../../constants/config";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../lib/auth";
import { timeStamp } from "../../utility/timestamp";
import Axios from "axios";
import db from "../../database";
import loginBackground from "../../assets/images/backgroundImage.png";
import logoIcon from "../../assets/images/NewLogoCW.svg";
import data from "../../constants/registrationLogin.json";
import "antd/dist/antd.css";
import "../style.css";
import moment from "moment";

const Login = () => {
  const [till, setTill] = useState("");
  const [loading, setLoading] = useState({ status: false, message: "" });
  const { setAuthTokens } = useAuth();
  const history = useHistory();
  const [logConfig, setLogConfig] = useState({});

  useEffect(() => {
    (async () => {
      const tillRegistrations = await db.tillRegistrations.toArray();
      if (tillRegistrations.length > 0) {
        setTill(tillRegistrations[0].searchKey);
        setTimeout(() => {
          userNameInputRef.current.focus();
        }, 100);
      } else {
        history.push("/registration");
      }
    })();
  }, []);

  const userNameInputRef = useRef();

  useEffect(() => {
    (async () => {
      const tillRegistrations = await db.tillRegistrations.toArray();
      if (tillRegistrations.length > 0) {
        history.push("/");
      }
    })();
  }, []);

  const handleKey = (e) => {
    const { keyCode } = e;
    if (keyCode >= 38 && keyCode <= 40) {
      setTimeout(() => {
        userNameInputRef.current.focus();
      }, 100);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  }, []);

  // const getDefaultCustomer = async (tillData, tokens) => {
  //   const defaultCustomerResponse = await Axios({
  //     url: serverUrl,
  //     method: "POST",
  //     data: {
  //       query: `query{
  //         customerSearch(mobileNo:"${tillData.tillAccess.csBunit.b2cCustomer.mobileNo}"){
  //           cwrCustomerId
  //           code
  //           name
  //           email
  //           mobileNo
  //           name
  //           pincode
  //           retlLoyaltyBalance
  //           b2cRegisteredstoreId
  //            iscredit
  //           balancepoints
  //           loyaltyLevel{
  //               cwrLoyaltyLevelId
  //               name
  //               accumulationRate
  //               redemptionRate
  //           }
  //           sCustomer{
  //             sCustomerID
  //             customerCategory{
  //               sCustomerCateforyId
  //               value
  //               name
  //               description
  //             }
  //           }
  //           csBunit{
  //             csBunitId
  //             name
  //           }
  //         }
  //       }`,
  //     },
  //     headers: {
  //       "Content-Type": "Application/json",
  //       Authorization: `${tokens.token_type} ${tokens.access_token}`,
  //     },
  //   });
  //   return defaultCustomerResponse.data.data.customerSearch[0];
  // };

  const onLogin = async (values) => {
    setLoading({ status: true, message: "" });
    const username = values.username.trim();
    const password = values.password.trim();

    const requestBody = decodeURIComponent(new URLSearchParams({ grant_type: "password", username, password }).toString());
    let resTokenData;

    try {
      resTokenData = await Axios.post(serverTokenUrl, requestBody, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Basic dGFsazJhbWFyZXN3YXJhbjpteS1zZWNyZXQ=",
        },
      });
    } catch (error) {
      setLoading({ status: false, message: "" });
      console.error(error);
      errorMessage(error.response.data.error, error.response.data.error_description);
      return null;
    }

    try {
      const resData = await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `query{
            tillData(user:"${username}"){   
              posScanConfigs{
                  cwrPosScanConfigId
                  scanTrxType
                  dataType
                  barcodeLength
                  startsWith
                  endsWith
                  customFormula
                  formula
              }
              loyaltyApply{
                  cwrLoyaltyLevelId
                  name
                  applicableFor
                  prodCategories{
                      mProductCategoryId
                      include
                      }
                  }  
              tillAccess{
                salesRep
                salesRepId
                cwrTillaccessId
                csClientId
                csUserId
                userAccessController
                cwrTill{
                   cwrTillID
                   searchKey
                   till
                   description
                   nextAssignedNumber
                   prefix
                   suffix
                   loyaltyProgram
                   accessController 
                   posType
                   manageCash
                   showopeningamount
                   showsalesamount
                   showexpectedamount
                   showdifferenceamount
                   shiftclose
                   shiftopen
                   eReceipt
                   printPreview
                   cashin
                   cashout
                   layAway
                   payNow
                   hardwareController{
                       imageUrl
                       printReceipt
                       weighingScale
                       payment
                       printBarcode
                   }
                   printTemplate{
                    cwrPrinttemplateId
                    name
                    htmlcode
                    htmlcode2
                    xmlcode
                    xmlcode2
                    obController
                  }
                  kotPrintTemplate{
                    cwrPrinttemplateId
                    name
                    htmlcode
                    htmlcode2 
                    xmlcode
                    xmlcode2
                  }
                  cancelKotPrintTemplate{
                    cwrPrinttemplateId
                    name
                    htmlcode
                    htmlcode2 
                    xmlcode
                    xmlcode2
                  }
                  tillCloseTemplate{
                     cwrPrinttemplateId
                    name
                    htmlcode
                    htmlcode2 
                  }
                  
                }
                csBunit{
                  csBunitId
                  name
                  value
                  cwrSCustomerId
                  cwrCsDoctypeId
                  cwrPcatalogueId
                  cwrSpricelistId
                  pCatalogueSaleType{
                    cwrPcatalogueSaletypeId          
                    isPromoApplicable
                    cwrSaletypeId
                  }
                  currencies{
                    csCurrencyId
                    currSymbol
                    isoCode
                    prcPrecision
                    stdPrecision
                    cstgPrecision
                    symbolRightSide
                    denominations{
                        value
                        seqNo
                    }
                    conversions{
                        csCurrencyIDTo
                        currencyFromRate
                       currencyToRate
                       validfromDate
                       validtoDate
                       isoCode
                    }
                }
                  b2cCustomer{
                    cwrCustomerId
                    code
                    name
                    email
                    mobileNo
                    pincode
                    retlLoyaltyBalance
                    b2cRegisteredstoreId
                    iscredit
                    balancePoints
                    loyaltyLevel{
                    cwrLoyaltyLevelId
                    name
                    accumulationRate
                    redemptionRate
                  }
              
                sCustomer{
                sCustomerID
                customerCategory{
                  sCustomerCateforyId
                  value
                  name
                  description
              }
                }
                  }
                  paymentMethodList{
                    cWRPaymentMethodID
                    sequenceNo
                    finPaymentmethodId
                    finFinancialAccountId
                    finDayCloseAccountId
                    name
                    integratedPayment
                    isloyalty
                    paymentProvider
                    iscredit 
                    isGiftCard
                    isDefault
                    csCurrencyId
                    isoCode
                    currSymbol
                  }
                  mWarehouse{
                    mWarehouseID
                    name
                  }
                  customerAddress{
                    sCustomerAddressID
                      line1
                      line2
                      line3
                      fulladdress
                      phone
                      city
                      postalcode
                      csCountry{
                          csCountryID
                          name          
                      }
                      csRegion{
                          csRegionID
                          name
                      }
                  }
                  locations{
                      csBunitLocationId
                      fulladdress
                      phone
                      contactPerson
                  }
                   salesRep{
                      code
                      name
                      salesRepresentId
                  }
                  
                }
                
              }
              status
              message
            }
          
          }`,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `${resTokenData.data.token_type} ${resTokenData.data.access_token}`,
        },
      });

      let tillData = resData.data.data.tillData;
      if (tillData.status === "200") {
        if (tillData !== null && tillData !== undefined) {
          Axios({
            url: serverUrl,
            method: "POST",
            data: {
              query: `query{
                getPOSConfig(application:"Logging")
                {
                  cwrPosConfigId
                  application
                  configJson
                }
              }`,
            },
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${resTokenData.data.token_type} ${resTokenData.data.access_token}`,
            },
          }).then((response) => {
            const loginConfig = JSON.parse(response.data.data.getPOSConfig[0].configJson);
            setLogConfig({ ...loginConfig });
            db.logConfiguration.add(loginConfig);
            const timeMark = timeStamp();
            const currentDate = new Date().toLocaleDateString("zh-Hans-CN");
            const loginArray = [];
            loginArray.push(`{
              type: "LGI",
              action: "LOG",
              description: "${timeMark}",
              date: "${currentDate}",
              time: "${timeMark}",
              orderNo: "",
              remarks: "",
            }`)
            if (loginConfig.login.filter((item) => item.log === "Y").length > 0) {
              db.logInformation.add({
                type: "LGI",
                action: "LOG",
                description: timeMark,
                date: currentDate,
                time: timeMark,
                orderNo: "",
                remarks: "",
              });
              Axios({
                url: serverUrl,
                method: "POST",
                data: {
                  query: `mutation {
                  upsertPOSLog(order: {
                      tillId: "${tillData.tillAccess.cwrTill.cwrTillID}"
                      userId: "${tillData.tillAccess.csUserId}" 
                      bUnitId: "${tillData.tillAccess.csBunit.csBunitId}", 
                      lines: [${loginArray}]
                    }) {
                    status   
                    message
                  }
                }`,
                },
                headers: {
                  "Content-Type": "Application/json",
                  Authorization: `${resTokenData.data.token_type} ${resTokenData.data.access_token}`,
                },
              })
            }
          });
          const tillAccessIndex = tillData.tillAccess.findIndex((accessTill) => accessTill.cwrTill.till === till);
          if (tillAccessIndex >= 0) {
            tillData.tillAccess = tillData.tillAccess[tillAccessIndex];
            // const defaultCustomer = await getDefaultCustomer(tillData, resTokenData.data);
            localStorage.setItem("username", username);
            localStorage.setItem("tillData", JSON.stringify(tillData));
            // localStorage.setItem("defaultCustomer", JSON.stringify(defaultCustomer));
            setAuthTokens(resTokenData.data);
            setLoading({ status: false, message: "" });
            const productCategories = await db.productCategories.toArray();
            syncRestaurantTables(resTokenData.data);
            if (productCategories.length > 0) {
              history.push("/dashboard");
            } else {
              history.push("/sync");
            }
          } else {
            setLoading({ status: false, message: "" });
            errorMessage("Login Failed", "User not configured for this Till");
          }
        } else {
          setLoading({ status: false, message: "" });
          errorMessage("Login Failed", "No Till Access Found");
        }
      } else {
        setLoading({ status: false, message: "" });
        errorMessage("Login Failed", "Till Access Failed");
      }
    } catch (error) {
      setLoading({ status: false, message: "" });
      errorMessage("Login Failed", "Invalid Username or Password");
      console.error(error);
    }
  };

  const syncRestaurantTables = (tokens) => {
    // const tokens = JSON.parse(localStorage.getItem("tokens"));
    const tillData = JSON.parse(localStorage.getItem("tillData"));
    return new Promise(async (restaurantTablesSuccess, restaurantTablesFailure) => {
      try {
        const paramsInput = {
          query: `query{
            getRestaurantTables(tillId:"${tillData.tillAccess.cwrTill.cwrTillID}"){
              cwrFbsectionId
              sectionName
              posTables{
                cwrFbTableId  
                cSBunitID
                cSClientID
                created
                createdby
                csWindowId
                isactive
                updated
                updatedby
                name
                capacity
                cwrFbFloorId
              }
            }  
          }`,
        };
        const response = await Axios({
          url: serverUrl,
          method: "POST",
          data: paramsInput,
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${tokens.token_type} ${tokens.access_token}`,
          },
        });
        const { getRestaurantTables } = response.data.data;
        if (response.status === 200 && getRestaurantTables.length > 0) {
          let tempData = [];
          let netWorkerror = false;
          let data = [{ value: "all", name: "All" }]
          getRestaurantTables.map((e,index) => {
            e.posTables.map((item) => {
              item.title = item.name;
              item.merge = true;
              item.cwrFbsectionId = e.cwrFbsectionId;
              item.sectionName = e.sectionName;
              item.table = item.name;
              item.color = "#a7c957";
              item.cwrFbsectionId = e.cwrFbsectionId;
              item.statusType = "OPN";
              item.originalIndex = index;
              tempData.push(item);
            });
            data.push({ value: e.cwrFbsectionId, name: e.sectionName });
          });
          await db.sectionTables.clear();
          await db.sectionTables.bulkPut(data);
          let occupiedData = [];
          await Axios({
            url: serverUrl,
            method: "POST",
            data: {
              query: `query {
                  getFBOrder(tableId:null,
                  status: "IP") {
                  cSBunitID
                  cSClientID
                  isactive
                  fbOrderId
                  fbTableId
                  fbSectionId
                  sOrderId
                  orderNo
                  guestName
                  guestType
                  referredBy
                  date1
                  status
                  salesRepId
                  guests
                  isCanceled
                  lines{
                  isCanceled
                  cSBunitID
                  cSClientID
                  isactive
                  fbOrderId
                  fbOrderLineId
                  mPoductId
                  mBatchId
                  description
                  csUomId
                  csTaxId
                  line
                  qty
                  unitPrice
                  listPrice
                  lineNet
                  lineTax
                  lineGross
                  sOrderLineId
                  isOrdered
                  meta{
                    key
                    value
                    fbOrderLineMetaId
                    }
                  }
                  }
              }`,
            },
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${tokens.token_type} ${tokens.access_token}`,
            },
          }).then((FbOrderData) => {
            if(FbOrderData?.data?.data?.getFBOrder.length>0){
              FbOrderData.data.data.getFBOrder.map(async(ele)=> {
                tempData.map( async(e) => {
                  if(e && e.cwrFbTableId === ele.fbTableId){
                    e.ordered = "Y";

                    // ---------------------------------------//

                let tempCart = { total: 0, tax: 0 };
                let newArray = [];
                let sOrderID = null;
                let flag = "Y";
                let price = 0;
                let tax = 0;
                let manualDiscountApplied = 0;
                ele.lines?.map((item, index) => {
                  let obj = JSON.parse(item?.meta[0]?.value);
                  if (item.isCanceled === "Y") {
                    obj.nettotal = 0;
                    obj.realPrice = 0;
                    obj.salePrice = 0;
                    obj.taxAmount = 0;
                    obj.total = 0;
                    obj.canceled = "Y";
                  } else {
                    flag = "N";
                  }
                  let manulaDiscount = 0;
        
                  if ((index === ele.lines.length - 1 && flag === "N") || tillData.tillAccess.salesRep === "N") {
                    sOrderID = obj.sOrderID;
                    manulaDiscount = obj.manualDiscountApplied;
                    tempCart.totalQty = obj.totalQty;
                    tempCart.customer = obj.customer;
                  }
                  manualDiscountApplied = obj.manualDiscountApplied;
                  obj.total = obj.total - obj.discount;
                  tempCart.tax += obj.weight * obj.taxAmount;
                  tempCart.total += obj.weight * obj.realPrice - obj.weight * obj.discount;
                  tempCart.manualDiscountApplied = manulaDiscount;
                  tax = tax + obj.weight * obj.taxAmount;
                  price = price + obj.weight * obj.realPrice;
                  obj.tax = item.csTaxId;
                  obj.order = item.isOrdered;
                  newArray.push(obj);
                });
                if (tempCart.customer === undefined) tempCart.customer = tillData.tillAccess.csBunit.b2cCustomer;
                let cartObj = {
                  ...tempCart,
                  items: [...newArray],
                  paid: 0,
                  change: 0,
                  roundOff: 0,
                  payments: [],
                  redemptionPoints: 0,
                  accumulationPoints: 0,
                  creditAmount: 0,
                  sOrderID: sOrderID,
                  discount: manualDiscountApplied,
                };
                let fbOrder = {
                  ...ele,
                  cwrFbTableId: ele.fbTableId,
                  isactive: ele.isactive ? "Y" : "N",
                  table: e.table,
                  color: e.color,
                  ordered: e.ordered,
                  waiter: ele.salesRepId,
                  isCanceled: ele.isCanceled,
                  cart:cartObj,
                  fbOrderStatus: "IP",
                  fbSectionId: ele.fbSectionId,
                  noOfPersons: ele.guests,
                  creatdeTime: ele.date1
                }

                await db.fbOrderData
                .where("fbOrderId")
                .equals(fbOrder.fbOrderId)
                .toArray()
                .then(async(ordersFetched) => {
                  if(ordersFetched.length>0){
                    await db.fbOrderData.update(fbOrder.fbOrderId, fbOrder);
                  }else{
                    await db.fbOrderData.put(fbOrder);
                  }
                })
                // ---------------------------------------//

                  }
                })
              })
            }
          })
          .catch((err) => {
            netWorkerror =true;
          })

          await Axios({
            url: serverUrl,
            method: "POST",
            data: {
              query: `query {
                getTableStatus(tableId: null) {
                cSBunitID
                cSClientID
                isactive
                fbTableId
                fbSectionId
                guestName
                guestType
                referredBy
                status
                salesRepId
                guests
                fbtableStatusId
                }
            }`,
            },
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${tokens.token_type} ${tokens.access_token}`,
            },
          }).then(async (response) => {
              if (response.data.data.getTableStatus.length > 0) {
                occupiedData = [...response.data.data.getTableStatus];
                await Axios({
                  url: serverUrl,
                  method: "POST",
                  data: {
                    query: `query {getTableReservation(tableId: null, date : "${moment(new Date()).format("YYYY-MM-DD")}") {
                    cSBunitID
                    cSClientID
                    isactive
                    fbTableReservationId
                    fbTableId
                    fbSectionId
                    customerName
                    noOfPeople
                    contactNo
                    guestType
                    referredBy
                    reservationTime
                    reservationDate
                    }
                }`,
                  },
                  headers: {
                    "Content-Type": "Application/json",
                    Authorization: `${tokens.token_type} ${tokens.access_token}`,
                  },
                }).then((res) => {
                  if (res.data.data.getTableReservation.length > 0) {
                    occupiedData.map((e) => {
                      res.data.data.getTableReservation?.map((ele) => {
                        if (e.fbTableId === ele.fbTableId) {
                          e.reservationTime = ele.reservationTime;
                        }
                      });
                    });
                  }
                });
              }
            }).catch((err)=>{
              netWorkerror =true;
            })
          tempData.map((e) => {
            if (occupiedData.length > 0) {
              occupiedData?.map((ele) => {
                if (e.cwrFbTableId === ele.fbTableId) {
                  if (ele.status === "OCU") {
                    e.color = "#bc4749";
                    e.noOfPersons = ele.guests;
                    e.guestName = ele.guestName;
                    e.guestType = ele.guestType;
                    e.referredBy = ele.referredBy;
                    e.fbtableStatusId = ele.fbtableStatusId;
                    e.statusType = "OCU";
                    e.waiter = ele.salesRepId;
                  } else if (ele.status === "RES") {
                    e.color = "#f2e8cf";
                    e.noOfPersons = ele.guests;
                    e.guestName = ele.guestName;
                    e.guestType = ele.guestType;
                    e.referredBy = ele.referredBy;
                    e.fbtableStatusId = ele.fbtableStatusId;
                    e.reservationTime = ele.reservationTime;
                    e.statusType = "RES";
                    e.waiter = ele.salesRepId;
                  } else if (ele.status === "OPN") {
                    e.color = "#a7c957";
                    e.noOfPersons = "";
                    e.guestName = "";
                    e.guestType = "";
                    e.referredBy = "";
                    e.fbtableStatusId = ele.fbtableStatusId;
                    e.statusType = "OPN";
                    delete e.cart;
                    delete e.fbOrder;
                    delete e.lines;
                    delete e.noOfPersons;
                    delete e.waiter;
                    delete e.salesRepId;
                    delete e.fbOrderId;
                    delete e.guestName;
                    delete e.guests;
                    delete e.guestType;
                    delete e.referredBy;
                    delete e?.fbOrderStatus;
                    delete e.id;
                    // delete e.fbtableStatusId;
                  }
                }
              });
            }
          });
          // if(netWorkerror === false){
          // }
          setTimeout(async()=>{
            await db.tableData.clear();
            await db.tableData.bulkPut(tempData);
          },500)
          // await db.restaurantTables.bulkAdd(getRestaurantTables);
          // setLoadingProductCategories(false);
        } else {
          restaurantTablesSuccess();
        }
      } catch (error) {
        // setLoadingProductCategories(false);
        restaurantTablesFailure(error);
      }
    });
  };

  const errorMessage = (modalTitle, modalContent) => {
    Modal.error({
      title: modalTitle,
      content: modalContent,
    });
  };

  const sectionStyle = {
    width: data.loginStyles.sectionStyle.width,
    height: data.loginStyles.sectionStyle.height,
    paddingTop: data.loginStyles.sectionStyle.paddingTop,
    backgroundImage: `url(${loginBackground})`,
    backgroundRepeat: data.loginStyles.sectionStyle.backgroundRepeat,
    backgroundSize: data.loginStyles.sectionStyle.backgroundRepeat,
  };

  const antIcon = <LoadingOutlined style={{ fontSize: data.loginStyles.fontSize }} spin />;

  return (
    <div className="aspectRatio">
      <div style={sectionStyle}>
        <Card style={data.loginStyles.loginCard}>
          <Spin indicator={antIcon} spinning={loading.status} tip={loading.message}>
            <div style={data.loginStyles.loginLogoDiv}>
              <img style={data.loginStyles.loginLogo} src={logoIcon} alt="" />
            </div>
            <Form onFinish={onLogin}>
              <Form.Item>
                <Row>
                  <Col span={8}>
                    <label style={data.loginStyles.loginLabel}>Till ID</label>
                  </Col>
                  <Col span={16}>
                    <label style={data.loginStyles.loginLabel}>{till}</label>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item name="username" rules={[{ required: true, message: "Please input your Username!" }]}>
                <Row gutter="5">
                  <Col span={8}>
                    <label>Username</label>
                  </Col>
                  <Col span={16}>
                    <Input ref={userNameInputRef} style={data.loginStyles.loginInput} placeholder="Username" />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item name="password" rules={[{ required: true, message: "Please input your password!" }]}>
                <Row gutter="5">
                  <Col span={8}>
                    <label>Password</label>
                  </Col>
                  <Col span={16}>
                    <Input.Password style={data.loginStyles.loginInput} placeholder="Password" />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item>
                <br />
                <Row gutter={[5, 0]}>
                  <Col span={12} />
                  <Col span={12}>
                    <Button size="large" style={data.loginStyles.btn} htmlType="submit">
                      Login
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
        <br />
        <div style={data.loginStyles.copyrightMainDiv}>
          <label style={data.loginStyles.copyright}>&emsp;&emsp;Copyright © {new Date().getFullYear()} CW Solutions Pvt Ltd | All rights reserved</label>
          <br />
          <label style={data.loginStyles.copyright}>&emsp;&emsp;www.cw.solutions</label>
        </div>
      </div>
    </div>
  );
};

export default Login;

import _ from "lodash";
import moment from "moment"
import { sendOrder } from "../../socket";
import axios from "axios";


export const cancelKOTPrinter = (printReciptData) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const printerURL = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
  let selectedTableData = JSON.parse(localStorage.getItem("tableName"));

let printData = Object.entries(printReciptData).map((element, index)=>{
  if(element[1].length>0){
   let newData = element[1].reduce((result, current) => {
      const category = current.productCategoryName;
      if (!result[category]) {
        result[category] = [];
      }
      if(current.order === "Y"){
      result[category].push(current);
      }
      return result;
    }, {});
    element.newData = newData
  }else{

  }
  return element
});


let data = "";
printData.map((item)=>{ 

  for (const key in item.newData) {
    if (Array.isArray(item.newData[key]) && item.newData[key].length === 0) {
      delete item.newData[key];
    }
  }

  if(Object.keys(item.newData).length !== 0){
    let printDataXml = tillData.tillAccess.cwrTill.cancelKotPrintTemplate.xmlcode2;
    printDataXml = printDataXml.replace("<output>", "");
    printDataXml = printDataXml.replace("</output>", "");
     printDataXml = printDataXml.replace("@@Printer@@", `${item[0]}`);
     printDataXml = printDataXml.replace("@@OrderNumber@@", `KOT - ${localStorage.getItem("orderId") === null || localStorage.getItem("orderId") === undefined || localStorage.getItem("orderId") === "null" ? "" : localStorage.getItem("orderId")}`);
     printDataXml = printDataXml.replace("@@name@@", `${selectedTableData.name === undefined ? "" : selectedTableData.name}`);
     printDataXml = printDataXml.replace("@@sectionName@@", `${selectedTableData.sectionName === undefined ? "" : selectedTableData.sectionName }`);
     printDataXml = printDataXml.replace("@@date@@", `${moment(new Date()).format("YYYY-MM-DD")}`);
     printDataXml = printDataXml.replace("@@time@@", `${moment(new Date()).format("HH:mm")}`);
     let newData = "";
    item[1].map((element,index)=>{
        newData += `
        <line>
            <text align="left" length="25"> ${element.name.substring(0,20).replace(/&/g, '&amp;')}</text>
            <text align="left" length="5">${element.weight}</text>
            <text align="left" length="12">${element?.notes === undefined ? "" :  element?.notes.substring(0,12)}</text>
           </line>
           <line>
            <text align="left" length="25"> ${element.name.substring(20).replace(/&/g, '&amp;')}</text>
            <text align="left" length="5"></text>
            <text align="left" length="12">${element?.notes === undefined ? "" :  element?.notes.substring(12)}</text>
           </line>
           <line>
           <text align="left" length="25"> ${element.name2 === null || element.name2 === null ? "" : element.name2.substring(0,20).replace(/&/g, '&amp;')}</text>
       </line>
       <line>
       <text align="left" length="25"> ${element.name2 === null || element.name2 === null ? "" : element.name2.substring(20).replace(/&/g, '&amp;')}</text>
   </line>
        `
    });
    printDataXml = printDataXml.replace("@@printReciptDataItems@@", `${newData}`);
    data += printDataXml;
  }
});

    let finalValue = `<?xml version="1.0" encoding="UTF-8"?><output>${data}</output>`
    const order = {
    CancelKOTPrint:finalValue.toString(),
    };

    const nWStatus = navigator.onLine ? "online" : "offline";
    if(nWStatus === "offline"){
      axios
      .post(`${printerURL}printer`, finalValue.toString(), {
        headers: {
          'Content-Type': 'application/xml; charset=UTF-8',
          'Accept': 'application/xml'
        }
      })
      .then(() => {
        console.log("XML print success !");
      })
      .catch((response) => {
        console.log("XML print failed !",response);
      });
    }else {
    sendOrder(order);
    }
};

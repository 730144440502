import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Card, Input, Button } from "antd";
import { useHistory } from "react-router-dom";
import { serverUrl } from "../../constants/config";
import { Link } from "react-router-dom";
import { clock } from "../../utility/clock";
import { timeStamp } from "../../utility/timestamp";
import { v4 as uuidv4 } from "uuid";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import MenuIcon from "../../assets/images/menu.png";
import POSLogo from "../../assets/images/cwPosLogo.png";
import Axios from "axios";
import db from "../../database";
import data from "../../constants/opentillClosetill.json";
import "../style.css";
import _ from "lodash";

const OpenTill = () => {
  const history = useHistory();
  const dynamicHeight = 0.5625;
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const tillaccess = JSON.parse(tillData.tillAccess.userAccessController);
  const tillLayout = parseInt(tillaccess?.layout === null || undefined ? 1 : tillaccess?.layout);

  const [currencyType, setCurrencyType] = useState({
    currSymbolLeft: "₹",
    currSymbolRight: "",
    stdPrecision: 2,
  });

  useEffect(() => {
    const tillSession = localStorage.getItem("tillSession");
    if (tillSession) {
      if (JSON.parse(tillSession).tillStatus === "open") history.push(tillLayout === 2 && tillaccess?.dineIn === "Y" ? "/table-management" : "/pos");
    } else {
      db.tillRegistrations.toArray().then((reg) => {
        if (reg.length > 0) {
          const curList = reg[0].tillAccess[0].csBunit.csCurrency;
          const csCurrencyId = curList.csCurrencyId;
          localStorage.setItem("csCurrencyId", csCurrencyId);
          setCurrencyType({
            currSymbolLeft: curList.symbolrightside ? "" : curList.currSymbol,
            currSymbolRight: curList.symbolrightside ? curList.currSymbol : "",
            stdPrecision: curList.stdPrecision,
          });
        }
      });
    }
  }, []);

  const [denominations, setDenominations] = useState([]);

  useEffect(() => {
    const modifiedCurrenciesList = currenciesList[0].denominations?.map((denomItem, denomIndex) => ({
      ...denomItem,
      count : 0,
      amount: 0
    }))
    setDenominations([...modifiedCurrenciesList]);
  }, [])

  // const [denominations, setDenominations] = useState([
  //   {
  //     denomination: 1,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 2,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 5,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 10,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 20,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 50,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 100,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 200,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 500,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 2000,
  //     count: 0,
  //     amount: 0,
  //   },
  // ]);

  const currentDate = new Date().toLocaleDateString();
  const [displayClock, setDisplayClock] = useState("");
  useEffect(() => {
    setDisplayClock(clock());
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const [totalAmount, setTotalAmount] = useState(0);

  const onFieldInput = (e) => {
    const { name, value } = e.target;
    const denominationName = parseInt(name);
    const denominationValue = parseInt(value);
    const denominationAmount = denominationName * denominationValue;
    const di = denominations.findIndex((d) => d.value === denominationName);
    denominations[di].count = isNaN(denominationValue) ? "" : denominationValue;
    denominations[di].amount = isNaN(denominationAmount) ? 0 : denominationAmount;
    let total = 0;
    denominations.forEach((denom) => {
      total += denom.amount;
    });
    setTotalAmount(total);
    setDenominations([...denominations]);
    console.log(total,denominations,{ name, value })
  };

  const onFieldOut = (e) => {
    if (e.target.value === "") {
      const di = denominations.findIndex((d) => d.value === parseInt(e.target.name));
      denominations[di].count = 0;
      setDenominations([...denominations]);
    }
  };

  const onFieldButton = (field, action) => {
    if (action === "plus") denominations[field].count += 1;
    if (action === "minus" && denominations[field].count > 0) denominations[field].count -= 1;
    const denominationAmount = denominations[field].value * denominations[field].count;
    const denominationAmountValue = isNaN(denominationAmount) ? 0 : denominationAmount;
    denominations[field].amount = denominationAmountValue;
    let total = 0;
    denominations.forEach((denom) => {
      total += denom.amount;
    });
    setTotalAmount(total);
    setDenominations([...denominations]);
  };

  const confirmBtnRef = useRef(null);
  const cancelBtnRef = useRef(null);

  const excuteOpenTill = () => {
    confirmBtnRef.current.disabled = true;
    cancelBtnRef.current.disabled = true;
    const tillSessionId = uuidv4().replace(/-/g, "").toUpperCase();
    const openTillData = {
      tillSessionId: tillSessionId,
      tillStatus: "open",
      openingTime: timeStamp(),
      closingTime: null,
      openingCashDenominations: denominations,
      closingCashDenominations: null,
      openingCash: totalAmount,
      closingCash: null,
      totalOpeningAmount: totalAmount,
      totalClosingExpectedAmount: null,
      totalClosingTransactions: null,
      totalClosingAmount: null,
    };
    localStorage.setItem("tillSession", JSON.stringify(openTillData));
    if (db.logConfiguration.toArray().then((fetched) => {
      fetched.map((item) => {
        item.dayOpening.filter((item) => item.log === "Y").length > 0;
      });
    })) { 
      const timeMark = timeStamp();
      const currentDate = new Date().toLocaleDateString("zh-Hans-CN");
      const dayOpeningArray = [];
      dayOpeningArray.push(`{
        type: "DON",
        action: "LOG",
        description: "${timeMark}",
        date: "${currentDate}",
        time: "${timeMark}",
        orderNo: "",
        remarks: "",
      }`)
      Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `mutation {
          upsertPOSLog(order: {
              tillId: "${tillData.tillAccess.cwrTill.cwrTillID}"
              userId: "${tillData.tillAccess.csUserId}" 
              bUnitId: "${tillData.tillAccess.csBunit.csBunitId}", 
              lines: [${dayOpeningArray}]
            }) {
            status   
            message
          }
        }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${tokens.token_type} ${tokens.access_token}`,
        },
      })
    }
    confirmBtnRef.current.disabled = false;
    cancelBtnRef.current.disabled = false;
    history.push(tillLayout === 2 && tillaccess?.dineIn === "Y" ? "/table-management" : "/pos");
  };

  const focusFirstElement = () => {
    setTimeout(()=>{
      cancelBtnRef.current.focus();
    },100);
  };

  const resetFocus = (index) => {
    if(index+1 === denominations.length){
      focusFirstElement();
    }
  };

  const handleHome = () => {
    history.push("/dashboard");
  }

  return (
    <div style={data.openTill.mainContainer}>
      <Row style={{ height: `${3.7 * dynamicHeight}vw` }}>
        <Col span={2}>
          <img src={MenuIcon} style={data.openTill.header.menuIcon} alt="" />
        </Col>
        <Col span={10}>
          <label style={data.openTill.header.posHeadLabel}>{tillData.tillAccess.csBunit.name}</label>
        </Col>
        <Col span={8}>
          <label style={data.openTill.header.timeFmt}>OPEN TILL</label>
        </Col>
        <Col span={4} style={data.openTill.header.right}>
          <img src={POSLogo} style={data.openTill.header.posLogoIcon} alt="" onClick={handleHome}/>
        </Col>
      </Row>
      <div style={data.openTill.mainCardContainer}>
        <Row gutter={10}>
          <Col span={18}>
            <Card bodyStyle={{ padding: 10 }} style={{ borderRadius: "3px" }}>
              <Card key="z" style={data.openTill.mainCardFirst} bodyStyle={{ padding: 0 }}>
                <Row style={{ background: "#EBEDED", padding: "1% 0%" }}>
                  <Col span={8} style={data.openTill.colAlign}>
                    <strong>Denomination</strong>
                  </Col>
                  <Col span={8} style={data.openTill.colAlign}>
                    <strong>Count</strong>
                  </Col>
                  <Col span={8} style={data.openTill.colAlign}>
                    <strong>Amount</strong>
                  </Col>
                </Row>
                <Row style={{ padding: 10 }}>
                  <Scrollbars autoHide className="tillScroll">
                    {denominations.map((denom, index) => (
                      <Row style={{ marginBottom: "2%" }}>
                        <Col span={8} style={data.openTill.colAlign}>
                          <Input tabIndex={-1} readOnly={true} style={data.openTill.currency} value={denom.value} />
                        </Col>
                        <Col span={8} style={data.openTill.colAlign}>
                          <div style={data.openTill.calInputDiv}>
                            <MinusOutlined style={data.openTill.decInpt} type="minus" onClick={() => onFieldButton(index, "minus")} />
                            <Form.Item onBlur={() => resetFocus(index)} style={data.openTill.countFormitem}>
                              <Input tabIndex={0} name={`${denom.value}`} value={denom.count} style={data.openTill.calInput} onChange={onFieldInput} onBlur={onFieldOut} />
                            </Form.Item>
                            <PlusOutlined style={data.openTill.incInpt} type="plus" onClick={() => onFieldButton(index, "plus")} />
                          </div>
                        </Col>
                        <Col span={8} style={data.openTill.colAlign}>
                          <Input
                            tabIndex={-1}
                            style={data.openTill.totalInput}
                            readOnly={true}
                            value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${denom.amount} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                          />
                        </Col>
                      </Row>
                    ))}
                    <span tabIndex={0}></span>
                  </Scrollbars>
                </Row>
              </Card>
            </Card>
          </Col>
          <Col span={6}>
            <Card bodyStyle={{ padding: 10 }} style={data.openTill.opentillRightCard}>
              <Link to="/dashboard">
                <Button tabIndex={1} className="focusButtonsTill" ref={cancelBtnRef} style={data.openTill.btnCancel}>
                  Cancel
                </Button>
              </Link>
              <Button tabIndex={1} className="focusButtonsTill" ref={confirmBtnRef} style={data.openTill.btnConfirm} onClick={excuteOpenTill}>
                Confirm
              </Button>
              <p style={data.openTill.emptyP} />
              <p style={data.openTill.emptyP}>Opened On</p>
              <div style={{ marginBottom: "0.5rem" }}>
                <span style={data.openTill.currentDate}>{currentDate}</span>
                <span style={data.openTill.displayClock}>{displayClock}</span>
              </div>
              <Form>
                <Form.Item style={{ marginBottom: "0.5rem" }}>
                  <p style={data.openTill.transactionsAmt}>Total Amount</p>
                  <Input
                    tabIndex={-1}
                    value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(totalAmount).toFixed(currencyType.stdPrecision)} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                    readOnly={true}
                    className="transactionAmtInput"
                  />
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OpenTill;
